import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-how-do-i-pay-for-tuition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-how-do-i-pay-for-tuition",
        "aria-label": "a how do i pay for tuition permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. How Do I Pay For Tuition?`}</h2>
    <h4 {...{
      "id": "tuition-fee-policy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#tuition-fee-policy",
        "aria-label": "tuition fee policy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tuition Fee Policy`}</h4>
    <p>{`The Board of Governors sets annual tuition fees each March for the following year. Parents receive this information each April.`}</p>
    <p>{`In addition to annual tuition, parents are responsible for the following fees:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Registration fee (for new students only)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Capital fee for new and returning students`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bus fees for students using school transportation`}</p>
      </li>
    </ul>
    <p>{`Cafeteria fees are paid directly to the catering service and are separate from other school fees.`}</p>
    <h4 {...{
      "id": "payment-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#payment-schedule",
        "aria-label": "payment schedule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Payment Schedule`}</h4>
    <p>{`All school fees are due each year at the time each student enters or returns to the School. Fees not paid by the deadline established by the Business Office are subject to a late fee. Bank transfer fees should be included in payments. Unpaid fees result in withheld records and report cards; consistent non-payment may result in student suspension, expulsion or non-renewal.`}</p>
    <p>{`Tuition fees must be paid through bank transfer. Cash payment may be accepted for payment of other small fees e.g. trips, additional uniforms .`}</p>
    <h4 {...{
      "id": "late-entry-and-early-withdrawal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#late-entry-and-early-withdrawal",
        "aria-label": "late entry and early withdrawal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Late Entry and Early Withdrawal`}</h4>
    <p>{`Students who enter PSI after the year begins or who withdraw early are charged in accordance with the School’s enrolment contract with the parents.`}</p>
    <h4 {...{
      "id": "re-enrolment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#re-enrolment",
        "aria-label": "re enrolment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Re-enrolment`}</h4>
    <p>{`Each spring, the School sends out information to parents regarding re-enrolment. Parents are asked to complete a form indicating that they are leaving, returning or unsure. Once parents have confirmed their intention to return, the School sends out an invoice for the following year. The School does not guarantee placement for any family that does not complete the form by the due date.`}</p>
    <h4 {...{
      "id": "bursary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#bursary",
        "aria-label": "bursary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bursary`}</h4>
    <p>{`PSI may approve financial assistance for the payment of tuition fees to families with demonstrated financial need. These may be approved if and when a family’s situation has changed, or where the presence of a child is felt to be of crucial importance to the class from an academic or wider social or organizational perspective. This may be in the form of a discount of up to ⅓ on tuition fees payable, depending on the financial or other relevant circumstances of applicants.`}</p>
    <p>{`Requests for financial support usually fall into two categories:`}</p>
    <p>{`• New applicants to the School, where a place has been offered but parents / guardians are unable to fund the tuition fees`}</p>
    <p>{`• Existing students where a change in parents’ / guardians’ circumstances has resulted in difficulty in meeting tuition fees and may result in the child being withdrawn from the School`}</p>
    <p>{`To apply, parents must provide financial information, including an account of income, expenditures, assets and liabilities. Bursary applications should be lodged with the PSI business manager by 31 May each year.`}</p>
    <p>{`Bursaries are awarded at the discretion of the PSI Board of Governors, are reviewed annually, and are provided for a maximum length of three school years. Awards may vary upwards or downwards depending on individual parents’ / guardians’ circumstances.`}</p>
    <p>{`Additional information, as well as the full Bursary Policy and Bursary Application Forms, are available from the PSI business manager and the school director.`}</p>
    <h2 {...{
      "id": "b-how-do-i-pay-for-other-services-at-the-school",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-do-i-pay-for-other-services-at-the-school",
        "aria-label": "b how do i pay for other services at the school permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Do I Pay For Other Services At The School?`}</h2>
    <h4 {...{
      "id": "no-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#no-fees",
        "aria-label": "no fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`No Fees`}</h4>
    <h5 {...{
      "id": "books-and-school-supplies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#books-and-school-supplies",
        "aria-label": "books and school supplies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Books and School Supplies`}</h5>
    <p>{`Students receive all textbooks, workbooks and notebooks free of charge. Textbooks and library books must be returned to the School at the end of the school year or when the student leaves the School. The student pays the replacement cost for lost or damaged books. Students may also bring other school supplies of their own, such as paper, pens and pencils, rulers, calculators, etc.`}</p>
    <h5 {...{
      "id": "phe--sports-uniform",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#phe--sports-uniform",
        "aria-label": "phe  sports uniform permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PHE / Sports Uniform`}</h5>
    <p>{`There is no official school uniform at PSI; however, a dress code of suitable clothing does exist for the school day (see page 81). In addition, students are expected to wear a PSI sports uniform for Physical and Health Education (PHE) lessons. We believe it is healthy and hygienic to change clothes for these lessons, as students do quite often get hot after physical exertion. Therefore, we have developed a set of `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1gEo16392tyU3iEFSMa4NrRTvGI31-nzD8PTTeNSm7Bo/edit"
      }}>{` PHE   Uniform Expectations `}</a>{` for all students.`}</p>
    <p>{`Grade 1 - Grade 10 students are required to wear the PSI PHE uniform for  all  PHE lessons. Students in Early Childhood (EC) and Kindergarten are not required to wear the kit, but they are encouraged to do so. Please note that the `}<strong parentName="p">{`minimum requirement for all students is the tee shirt, shorts, a sweatshirt, sweatpants and the House Team tee shirt.`}</strong></p>
    <p>{`These items are provided to all students annually and are free of charge. Other PHE uniform items are available for purchase, as are additional sets of the items above. Students in PHE are also welcome to wear any official PSI sports team uniforms or their PSI House Team tee shirt. `}<strong parentName="p">{`Students should also have appropriate shoes.`}</strong></p>
    <p>{`For swimming lessons, students must have appropriate swimwear. This includes suitable swimwear for school (style and length), goggles, swim caps (if hair is longer than shoulders), towel, and flip flops or sandals. All of these should be in a swimming bag. These are not provided by the School.`}</p>
    <h5 {...{
      "id": "week-without-walls",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#week-without-walls",
        "aria-label": "week without walls permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Week without Walls`}</h5>
    <p>{`The annual Week without Walls in Secondary School is included in school fees. Other than providing your child with some ‘fun money,’ all other expenses are paid by the School.`}</p>
    <h5 {...{
      "id": "fees-to-external-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#fees-to-external-providers",
        "aria-label": "fees to external providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fees to External Providers`}</h5>
    <p>{`PSI’s food and transportation services are run by external providers who charge separately from any school fees.`}</p>
    <h4 {...{
      "id": "cafeteria-fee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#cafeteria-fee",
        "aria-label": "cafeteria fee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cafeteria Fee`}</h4>
    <p>{`All students (Primary and Secondary) use their smart-card for all purchases in the cafeteria, both at lunch time and for a la carte purchases during the day, as the cafeteria does not accept cash. Students simply swipe their cards at the card reader near the cash register, and the amount of the purchase is deducted from the card.`}</p>
    <p>{`Kiosks in the Building B reception area and in Building A near the cafeteria allow parents and students to read and update the card at any time. Students can add funds to their cards at the kiosks. One must have the card with them to use the machine.`}</p>
    <h4 {...{
      "id": "transportation-fee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#transportation-fee",
        "aria-label": "transportation fee permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transportation Fee`}</h4>
    <p>{`Students who opt to use the school’s bus service will be billed by the transport company at the beginning of the school year or when they begin using the bus service.`}</p>
    <h4 {...{
      "id": "additional-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#additional-fees",
        "aria-label": "additional fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Additional Fees`}</h4>
    <h5 {...{
      "id": "student-travel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#student-travel",
        "aria-label": "student travel permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Student Travel`}</h5>
    <p>{`For CEESA travel or other competitions, the athletic director or the assistant athletic director sends information to parents about costs, and the cashier then bills parents for all travel expenses. In general, parents pay travel expenses and students are housed with families from the host school community.`}</p>
    <h5 {...{
      "id": "transferring-to-another-school",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#transferring-to-another-school",
        "aria-label": "transferring to another school permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transferring to Another School`}</h5>
    <p>{`All parents withdrawing their Primary or Secondary children from PSI should inform the Admissions Office (`}<a parentName="p" {...{
        "href": "mailto:admissions@psi.kiev.ua"
      }}>{`admissions@psi.kiev.ua`}</a>{`), giving as much notice as possible.`}</p>
    <p>{`The School normally requires 7 - 10 days to process this information. PSI requires that parents submit the name of the person in the receiving school and the receiving school’s address, phone and fax details. Courier charges are at the expense of the parents and must be paid in advance of sending transfer documents. Students who need to take entrance tests are charged a small fee for supervision of those tests.`}</p>
    <Wrapper padding='md' frame='solid-grey' lastChildMargin mdxType="Wrapper">
      <p><strong parentName="p">{`Please note`}</strong>{` that all application forms and letters of reference are confidential and will be sent only to the receiving school.`}</p>
      <p>{`Students withdrawing from PSI must be in good financial standing to receive any school documentation, including letters of reference, report cards, transcripts and diplomas. This includes all payments to the School’s external providers.`}</p>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      